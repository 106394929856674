<ng-template #content let-modal>
    <!-- <div class="modal-header"> -->
      <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
    <!-- </div> -->
    <div class="modal-content">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span id="closetext" aria-hidden="true">&times;</span>
      </button>
      <div class="rows" style="align-items: center; justify-items: center;">
        <div class="logo" >
          <img src="assets/images/logos/logo_header.png" width="200" style="align-self: center; justify-self: center;" alt="logo modal">
        </div>
        <h2>Bienvenido</h2>
        <p>Quieres información acerca de tu conversión suscríbete.</p>
        <form (ngSubmit)="modal.close('Submit')" #userForm="ngForm">
          <div class="csn-inputs">
            <div class="csn-input">
              <input type="text" name="name" [(ngModel)]="user.name" class="csni-field" placeholder="Nombre *" required>
              <input type="text" name="lastname" [(ngModel)]="user.lastname" class="csni-field" placeholder="Apellido *" required>
            </div>

            <div class="csn-input">
              <input type="email" name="email" [(ngModel)]="user.email" class="csni-field" placeholder="Correo Electrónico *" required>
              <input type="tel" name="tel" [(ngModel)]="user.tel" class="csni-field" placeholder="Teléfono *" required>
            </div>

          </div>
          <h4>¿Qué tipo de servicio te interesa?</h4>
          <div class="main-radios">
            <div class="radios">
              <img class="image" src="https://www.gazo.com.mx/wp-content/uploads/2021/03/Capa-893.png" alt="Conversiones"><br>
              <input type="radio" name="service" [(ngModel)]="user.service" value="Refacciones" required checked><br>
              <label>Refacciones</label>
            </div>
            <div class="radios">
              <img class="image" src="https://www.gazo.com.mx/wp-content/uploads/2021/03/Capa-892.png" alt="Financiamiento"><br>
              <input type="radio" name="service" [(ngModel)]="user.service" value="Sobre Pedido" required><br>
              <label>Sobre Pedido</label>
            </div>
            <div class="radios">
              <img class="image" src="https://www.gazo.com.mx/wp-content/uploads/2021/03/Capa-894.png" alt="Estaciones"><br>
              <input type="radio" name="service" [(ngModel)]="user.service" value="Capacitaciones" required><br>
              <label>Capacitaciones</label>
            </div>
          </div>
          <div class="csn-footer">
            <input type="submit" value="Suscribirse" class="submit" [disabled]="!userForm.form.valid">
          </div>
        </form>
      </div>
    </div>
  </ng-template>
