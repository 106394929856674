<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery" *ngIf="product.pictures && product.pictures.length">
            <app-product-gallery [productLayout]="layout" [idProduct]="product.id" [isTraining]="true" [images]="product.pictures"></app-product-gallery>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Favoritos" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Comparar" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>

            <h1 class="product__name">{{ product.title }}</h1>
            <div class="product__rating">
                <div class="product__rating-stars">
                    <app-rating [value]="product.rating"></app-rating>
                </div>
                <div class="product__rating-legend">
                    <a href="" appClick>{{ product.reviews }} Comentarios</a><span>/</span><a href="" appClick>Escribir un comentario</a>
                </div>
            </div>
            <div class="product__description" [innerHTML]="product.description"></div>
            <ul class="product__features">
                <li>Sesiones: {{ product.sessions }}</li>
                <li>Clases: {{ product.clases }}</li>
                <li>Horas: {{ product.hrs }}</li>
                <li>Precio Original: {{ product.priceOriginal }}</li>
            </ul>
            <ul class="product__meta">
                <li class="product__meta-availability">Disponible: <span class="text-success"> {{ product.stock }} plazas</span></li>
                <li>Categoría: <a href="" appClick>{{ product.category }}</a></li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                Disponible: <span class="text-success">{{ product.stock }} plazas</span>
            </div>

            <div class="product__prices">
                <ng-container *ngIf="product.priceOriginal">
                    <span class="product__new-price">{{ product.price | currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.priceOriginal | currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.priceOriginal">
                    {{ product.price | currencyFormat }}
                </ng-container>
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <div class="form-group product__option">
                    <label class="product__option-label">Cantidad</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg" [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Agregar al carro</button>
                        </div>
                        <div class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Favoritos" [ngClass]="{'btn-loading': addingToWishlist}" appClick (click)="addToWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                            </button>
                        </div>
                        <div class="product__actions-item product__actions-item--compare">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Comparar" [ngClass]="{'btn-loading': addingToCompare}" appClick (click)="addToCompare()">
                                <app-icon name="compare-16" size="16"></app-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group product__option mt-2 border px-2" *ngIf="product.user">
                    <label class="product__option-label"><span class="text-success">Distribuido por</span>: {{ product.user.profile.name_company }}</label>
                </div>
                <div class="form-group product__option mt-2 border px-2" *ngIf="!product.user">
                    <label class="product__option-label"><span class="text-success">Distribuido por</span>: Eneralt</label>
                </div>
            </form>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
    </div>
</div>
