<ng-template #modal>
    <div class="quickview">
        <button class="quickview__close" type="button" appClick (click)="modalRef.hide()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>

        <app-product *ngIf="product?.sku !== undefined"  [product]="product" layout="quickview"></app-product>
        <app-capacitacion *ngIf="product?.sku === undefined" [product]="product" layout="quickview"></app-capacitacion>
    </div>
</ng-template>
