import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {URL_SERVICES} from '../../../config/config';
@Component({
    selector: 'app-block-modal',
    templateUrl: './block-modal.component.html',
    styleUrls: ['./block-modal.component.scss']
})
export class BlockModalComponent implements OnInit {
    @ViewChild('content') modalContent: TemplateRef<any>;
    user = {
        name: '',
        lastname: '',
        email: '',
        tel: '',
        service: 'Refacciones'
    };

      constructor(private modalService: NgbModal, private http: HttpClient,  private toastr: ToastrService) { }

      ngOnInit() {
      }

      ngAfterViewInit() {
        // Abre el modal automáticamente después de que la vista se haya inicializado
        this.openModal();
    }

      openModal() {
        this.modalService.open(this.modalContent, {centered: true}).result.then((result) => {
          if (result === 'Submit') {
            this.onSubmit();
          }
        }, (reason) => {
          // Modal cerrado sin enviar datos
        });
      }

      onSubmit() {
        this.http.post(`${URL_SERVICES}submit-user-data`, this.user,{
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }).subscribe(
          response => {
            console.log('Datos enviados con éxito', response);
          },
          error => {
            console.error('Error al enviar datos', error);
          }
        );
      }

}
