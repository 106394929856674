import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    @Input() withDepartments = false;

    options = {
        nav: false,
        dots: true,
        loop: true,
        navSpeed: 2,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplaySpeed: 1000,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    slides = [
        {
            title: 'Mercado Libre',
            subtitle: 'Ahorra en nuestros productos',
            url:'https://www.energiasalternasonline.com',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: 'assets/images/slides/slidemercado.png',
            image_full: 'assets/images/slides/slidemercado.png',
            image_mobile: 'assets/images/slides/slidemercado.png'
        },
        {
            title: 'AHORRA',
            subtitle: 'Hasta un 40% en combustible',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: 'assets/images/slides/slide.png',
            image_full: 'assets/images/slides/slide.png',
            image_mobile: 'assets/images/slides/slide.png'
        },
        {
            title: 'Screwdrivers<br>Professional Tools',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: 'assets/images/slides/slideentrega.png',
            image_full: 'assets/images/slides/slideentrega.png',
            image_mobile: 'assets/images/slides/slideentrega.png'
        },
        {
            title: 'One more<br>Unique header',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: 'assets/images/slides/slideiva.png',
            image_full: 'assets/images/slides/slideiva.png',
            image_mobile: 'assets/images/slides/slideiva.png'
        },
        {
            title: 'One more<br>Unique header',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: 'assets/images/slides/slidekits.png',
            image_full: 'assets/images/slides/slidekits.png',
            image_mobile: 'assets/images/slides/slidekits.png'
        },
        {
            title: 'One more<br>Unique header',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: 'assets/images/slides/slidepago.png',
            image_full: 'assets/images/slides/slidepago.png',
            image_mobile: 'assets/images/slides/slidepago.png'
        }
    ];

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService
    ) { }
}
