<div class="site-header">
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="/">
                <img src="assets/images/logos/logo_header.png" alt="" width="160px">
            </a>
        </div>
        <div class="site-header__search">
            <app-search location="header"></app-search>
        </div>

        <app-icon class="ml-3 color-blue font-weight-bold" name="person-20" size="20"></app-icon>
        <div class="site-header__phone ml-2 mr-3 color-green font-weight-bold">
            <div class="text-left text-uppercase" *ngIf="_companyService.isLogin()">Hola</div>
            <div class="site-header__phone-title" *ngIf="!_companyService.isLogin()"><a routerLink="/cuenta/login"> Login </a>| <a routerLink="/cuenta/login">Registrarse</a></div>
            <div class="site-header__phone-title" *ngIf="_companyService.isLoginCompany()">{{ _companyService.user.profile.name_company }}</div>
            <div class="site-header__phone-title" *ngIf="_companyService.isLoginClient()">{{ _companyService.user.profile.name }} {{ _companyService.user.profile.lastname }}</div>
            <!-- <div class="site-header__phone-number">{{ store.primaryPhone }}</div> -->
        </div>
        <!-- <app-indicator icon="cart-20" [counter]="cart.quantity$|async">
        </app-indicator> -->
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'" stickyMode="pullToShow"></app-header-nav>
    </div>
</div>
