<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery" *ngIf="product.pictures">
            <app-product-gallery [productLayout]="layout" [idProduct]="product.id" [images]="product.pictures"></app-product-gallery>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Favoritos" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Comparar" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>

            <h1 class="product__name">{{ product.name }}</h1>
            <div class="product__rating">
                <div class="product__rating-stars">
                    <app-rating [value]="product.rating"></app-rating>
                </div>
                <div class="product__rating-legend">
                    <a href="" appClick>{{ product.reviews }} Comentarios</a><span>/</span><a href="" appClick>Escribir un comentario</a>
                </div>
            </div>
            <div class="product__description">
                <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare elementum, libero nibh lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius nisi. -->
                {{product.shortDetail}}
            </div>
            <ul class="product__features">
                <li>Speed: 750 RPM</li>
                <li>Power Source: Cordless-Electric</li>
                <li>Battery Cell Type: Lithium</li>
                <li>Voltage: 20 Volts</li>
                <li>Battery Capacity: 2 Ah</li>
            </ul>
            <ul class="product__meta">
                <li class="product__meta-availability">Disponible: <span class="text-success">En Stock {{ product.stock }} piezas</span></li>
                <li>Categoría: <a href="" appClick>{{product.category}}</a></li>
                <li>SKU: {{ product.sku }}</li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                Disponible: <span class="text-success">En Stock {{ product.stock }} piezas</span>
            </div>

            <div class="product__prices">
                <ng-container *ngIf="product.compareAtPrice">
                    <span class="product__new-price">{{ product.price|currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">
                    {{ product.price|currencyFormat }}
                </ng-container>
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <!-- <div class="form-group product__option">
                    <label class="product__option-label">Color</label>
                    <div class="input-radio-color">
                        <div class="input-radio-color__list">
                            <label class="input-radio-color__item input-radio-color__item--white" style="color: #fff;" data-toggle="tooltip" title="White">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ffd333;" data-toggle="tooltip" title="Yellow">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ff4040;" data-toggle="tooltip" title="Red">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item input-radio-color__item--disabled" style="color: #4080ff;" data-toggle="tooltip" title="Blue">
                                <input type="radio" name="color" disabled>
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="form-group product__option">
                    <label class="product__option-label">Marca</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label>
                                <input type="radio" name="material">
                                <span>{{ product.brandName }}</span>
                            </label>
                            <!-- <label>
                                <input type="radio" name="material">
                                <span>Wood</span>
                            </label>
                            <label>
                                <input type="radio" name="material" disabled>
                                <span>Plastic</span>
                            </label> -->
                        </div>
                    </div>
                </div>
                <div class="form-group product__option">
                    <label class="product__option-label">Cantidad</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg" [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Agregar al carro</button>
                        </div>
                        <div class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Favoritos" [ngClass]="{'btn-loading': addingToWishlist}" appClick (click)="addToWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                            </button>
                        </div>
                        <div class="product__actions-item product__actions-item--compare">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Comparar" [ngClass]="{'btn-loading': addingToCompare}" appClick (click)="addToCompare()">
                                <app-icon name="compare-16" size="16"></app-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group product__option mb-0 border px-2">
                    <label class="product__option-label"><span class="text-success">Tiempo de entrega</span>: {{ product.days }} </label>
                </div>
                <div class="form-group product__option mt-2 mb-0 border px-2">
                    <label class="product__option-label"><span class="text-success">Origen de Producto</span>: {{ product.origen }}</label>
                </div>
                <div class="form-group product__option mt-2 border px-2">
                    <label class="product__option-label" *ngIf="product.user"><span class="text-success">Distribuido por</span>: {{ product.user.profile.name_company }}</label>
                    <label class="product__option-label" *ngIf="!product.user"><span class="text-success">Distribuido por</span>: Eneralt</label>
                </div>
            </form>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
        <div class="product__footer">
            <!-- <div class="product__tags tags">
                <div class="tags__list">
                    <a href="" appClick>Mounts</a>
                    <a href="" appClick>Electrodes</a>
                    <a href="" appClick>Chainsaws</a>
                </div>
            </div> -->

            <div class="product__share-links share-links">
                <ul class="share-links__list">
                    <li class="share-links__item share-links__item--type--like"><a href="https://www.facebook.com/sharer.php?u=https://eneralt.com.mx{{ url }}" target="_blank" appClick>Like</a></li>
                    <li class="share-links__item share-links__item--type--tweet"><a href="https://twitter.com/intent/tweet?text=https://eneralt.com.mx{{ url }}" target="_blank" appClick>Tweet</a></li>
                    <!-- <li class="share-links__item share-links__item--type--pin"><a href="" appClick>Pin It</a></li>
                    <li class="share-links__item share-links__item--type--counter"><a href="" appClick>4K</a></li> -->
                </ul>
            </div>
        </div>
    </div>
</div>