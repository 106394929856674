<div class="site-footer mt-0">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-3">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                    <app-footer-links header="SERVICIO AL CLIENTE" [links]="[
                        {label: 'Contactanos',             url: '/contacto'},
                        {label: 'Envios', url: ''},
                        {label: 'Devoluciones',       url: '/devoluciones'},
                        {label: 'Preguntas Frecuentes',               url: '/preguntas-frecuentes'},
                        {label: 'Aviso de Privacidad',               url: ''},
                        {label: 'Terminos y Condiciones',               url: '/terminos-y-condiciones'}
                    ]"></app-footer-links>
                </div>
                <div class="col-6 col-md-4 col-lg-2">
                    <app-footer-links header="TIENDA" [links]="[
                        {label: 'Empresas', url: '/empresa/login'},
                        {label: 'Productos', url: '/productos'},
                        {label: 'Servicios',  url: '/servicios-conversiones'},
                        {label: 'Capacitaciones',      url: '/capacitaciones'}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div>
            </div>
        </div>
        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                Copyright <i class="fas fa-copyright    "></i> 2020 ENERALT, S.A DE C.V.. Todos los derechos reservados, <a href="http://www.dosbytes.com.mx" title="Diseño web" target="_blank">Diseño web</a> por dosbytes.com.mx </div>
            <div class="site-footer__payments">
                <img src="assets/images/payments.png" alt="">
            </div>
        </div>
    </div>
    <app-totop></app-totop>
</div>