<app-block-slideshow [withDepartments]="true"></app-block-slideshow>

<app-block-features layout="boxed"></app-block-features>

<app-block-products-carousel header="Nuestros Productos" layout="grid-4" [loading]="loadingPopular" [products]="featuredProducts"></app-block-products-carousel>

<app-block-categories header="Categorias" layout="compact" [categories]="popularCategories$"></app-block-categories>

<app-block-modal header="Nuestros Productos" layout="grid-4"></app-block-modal>

<!--

<app-block-products header="Bestsellers" layout="large-first" [products]="bestsellers$|async"></app-block-products>


<app-block-categories header="Popular Categories" layout="classic" [categories]="popularCategories$|async"></app-block-categories>

<app-block-products-carousel header="New Arrivals" layout="horizontal" [rows]="2" [loading]="latestProducts.loading" [products]="latestProducts.products" [groups]="latestProducts.groups" (groupChange)="groupChange(latestProducts, $event)"></app-block-products-carousel>
-->
<app-block-products-carousel header="Recien Publicados" layout="grid-5" [rows]="1" [loading]="loadingRecent"  [products]="latestProducts"></app-block-products-carousel>
<app-block-posts header="Noticias" layout="grid-nl" [posts]="posts"></app-block-posts>
<!--
<app-block-brands [brands]="brands$|async"></app-block-brands>

<app-block-product-columns>
    <app-block-product-columns-item header="Top Rated Products" [products]="columnTopRated$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Special Offers" [products]="columnSpecialOffers$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Bestsellers" [products]="columnBestsellers$|async"></app-block-product-columns-item>
</app-block-product-columns>
-->
