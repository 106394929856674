import { Injectable } from "@angular/core";
import { DirectoryService } from "./directory.service";

@Injectable({
    providedIn: "root",
})
export class SharedService {
    pageActual: number = 1;
    pageActualPromotions: number = 1;
    pageActualSearch: number = 1;
    beforeUrl: string;
    URL_SERVICES_IMG:string;
    constructor(public directoryService: DirectoryService) {
        this.URL_SERVICES_IMG = directoryService.URL_SERVICES_IMG;
    }
}
