import { MobileMenuItem } from '../app/shared/interfaces/mobile-menu-item';

export const mobileMenu: MobileMenuItem[] = [
    {type: 'link', label: 'Inicio', url: '/'},
    {type: 'link', label: 'Nosotros', url: '/nosotros'},
    {type: 'link', label: 'Productos', url: '/productos', children: [
        {type: 'link', label: 'Sobrepedido', url: '/productos'},
        {type: 'link', label: 'Mercado Libre', url: "https://www.energiasalternasonline.com", isExternal: true},
    ]},
    {type: 'link', label: 'Servicios', url: '/servicios-conversiones'},
    {type: 'link', label: 'Capacitaciones', url: '/capacitaciones'},
    {type: 'link', label: 'Directorio', url: '/directorio'},
    {type: 'link', label: 'Blog', url: '/blog/category-grid'},
    {type: 'link', label: 'Contacto', url: '/contacto'},
    // {type: 'link', label: 'Inicio', url: '/', children: [
    //     {type: 'link', label: 'Home 1', url: '/'},
    //     {type: 'link', label: 'Home 2', url: '/home-two'}
    // ]},

    // {type: 'link', label: 'Categories', url: '/productos', children: [
    //     {type: 'link', label: 'Power Tools', url: '/productos', children: [
    //         {type: 'link', label: 'Engravers',           url: '/productos'},
    //         {type: 'link', label: 'Wrenches',            url: '/productos'},
    //         {type: 'link', label: 'Wall Chaser',         url: '/productos'},
    //         {type: 'link', label: 'Pneumatic Tools',     url: '/productos'}
    //     ]},
    //     {type: 'link', label: 'Machine Tools', url: '/productos', children: [
    //         {type: 'link', label: 'Thread Cutting',      url: '/productos'},
    //         {type: 'link', label: 'Chip Blowers',        url: '/productos'},
    //         {type: 'link', label: 'Sharpening Machines', url: '/productos'},
    //         {type: 'link', label: 'Pipe Cutters',        url: '/productos'},
    //         {type: 'link', label: 'Slotting machines',   url: '/productos'},
    //         {type: 'link', label: 'Lathes',              url: '/productos'}
    //     ]}
    // ]},

    // {type: 'link', label: 'Shop', url: '/productos/power-tools', children: [
    //     {type: 'link', label: 'Shop Grid', url: '/productos/power-tools', children: [
    //         {type: 'link', label: '3 Columns Sidebar',  url: '/productos/power-tools'},
    //         {type: 'link', label: '4 Columns Full',     url: '/shop/category-grid-4-columns-full'},
    //         {type: 'link', label: '5 Columns Full',     url: '/shop/category-grid-5-columns-full'}
    //     ]},
    //     {type: 'link', label: 'Shop List',          url: '/shop/category-list'},
    //     {type: 'link', label: 'Shop Right Sidebar', url: '/shop/category-right-sidebar'},
    //     {type: 'link', label: 'Product',            url: '/shop/product-standard', children: [
    //         {type: 'link', label: 'Product',            url: '/shop/product-standard'},
    //         {type: 'link', label: 'Product Alt',        url: '/shop/product-columnar'},
    //         {type: 'link', label: 'Product Sidebar',    url: '/shop/product-sidebar'}
    //     ]},
    //     {type: 'link', label: 'Cart',        url: '/carro'},
    //     {type: 'link', label: 'Checkout',    url: '/carro/checkout'},
    //     {type: 'link', label: 'Order Success',    url: '/carro/checkout/exito'},
    //     {type: 'link', label: 'Wishlist',    url: '/favoritos'},
    //     {type: 'link', label: 'Compare',     url: '/shop/compare'},
    //     {type: 'link', label: 'Track Order', url: '/shop/track-order'}
    // ]},

    // {type: 'link', label: 'Account', url: '/account', children: [
    //     {type: 'link', label: 'Login',           url: '/cuenta/login'},
    //     {type: 'link', label: 'Dashboard',       url: '/cuenta/dashboard'},
    //     {type: 'link', label: 'Edit Profile',    url: '/cuenta/perfil'},
    //     {type: 'link', label: 'Order History',   url: '/cuenta/ordenes'},
    //     {type: 'link', label: 'Order Details',   url: '/cuenta/ordenes/5'},
    //     {type: 'link', label: 'Address Book',    url: '/cuenta/direcciones'},
    //     {type: 'link', label: 'Edit Address',    url: '/cuenta/direcciones/5'},
    //     {type: 'link', label: 'Change Password', url: '/cuenta/contrasena'}
    // ]},

    // {type: 'link', label: 'Blog', url: '/blog', children: [
    //     {type: 'link', label: 'Blog Classic',         url: '/blog/category-classic'},
    //     {type: 'link', label: 'Blog Grid',            url: '/blog/category-grid'},
    //     {type: 'link', label: 'Blog List',            url: '/blog/category-list'},
    //     {type: 'link', label: 'Blog Left Sidebar',    url: '/blog/category-left-sidebar'},
    //     {type: 'link', label: 'Post Page',            url: '/blog/post-classic'},
    //     {type: 'link', label: 'Post Without Sidebar', url: '/blog/post-full'}
    // ]},

    // {type: 'link', label: 'Pages', url: '/site', children: [
    //     {type: 'link', label: 'Nosotros',             url: '/nosotros'},
    //     {type: 'link', label: 'Contacto',           url: '/contacto'},
    //     {type: 'link', label: '404',                  url: '/not-found'},
    //     {type: 'link', label: 'Terms And Conditions', url: '/terminos'},
    //     {type: 'link', label: 'FAQ',                  url: '/preguntas-frecuentes'},
    //     {type: 'link', label: 'Components',           url: '/site/components'},
    //     {type: 'link', label: 'Typography',           url: '/site/typography'}
    // ]},

    // {type: 'button', label: 'Currency', children: [
    //     {type: 'button', label: '€ Euro',           data: {currency: 'EUR'}},
    //     {type: 'button', label: '£ Pound Sterling', data: {currency: 'GBP'}},
    //     {type: 'button', label: '$ US Dollar',      data: {currency: 'USD'}},
    //     {type: 'button', label: '₽ Russian Ruble',  data: {currency: 'RUB'}}
    // ]},

    // {type: 'button', label: 'Language', children: [
    //     {type: 'button', label: 'English', data: {language: 'EN'}},
    //     {type: 'button', label: 'French',  data: {language: 'FR'}},
    //     {type: 'button', label: 'German',  data: {language: 'DE'}},
    //     {type: 'button', label: 'Russian', data: {language: 'RU'}},
    //     {type: 'button', label: 'Italian', data: {language: 'IT'}}
    // ]}
];
