import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../interfaces/brand';
import { Product } from '../interfaces/product';
import { ProductsList } from '../interfaces/list';
import { SerializedFilterValues } from '../interfaces/filter';
import {
    getBestsellers,
    getFeatured,
    getLatestProducts,
    getProduct,
    getRelatedProducts,
    getSpecialOffers,
    getTopRated,
    getShopCategoriesBySlugs,
    getShopCategoriesTree,
    getShopCategory,
    getBrands,
    getProductsList,
} from '../../../fake-server';
import { getSuggestions } from 'src/fake-server/database/products';
import { URL_SERVICES } from '../../config/config';
import { CompanyService } from '../services/company.service';
import { SharedService } from '../services/shared.service';

export interface ListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    filterValues?: SerializedFilterValues;
}

const URL_API = URL_SERVICES;
@Injectable({
    providedIn: 'root'
})
export class ShopService {

    URL_API = URL_SERVICES;

    constructor(
        private http: HttpClient,
        private _companyService: CompanyService,
        private sharedService: SharedService
    ) { }

    /**
     * Returns category object by slug.
     *
     * @param slug - Unique human-readable category identifier.
     */
    getCategory(slug: string): Observable<Category> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories/power-tools.json
         *
         * where:
         * - power-tools = slug
         */
        // return this.http.get<Category>(`https://example.com/api/shop/categories/${slug}.json`);

        // This is for demonstration purposes only. Remove it and use the code above.
        return getShopCategory(slug);
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getCategories(): Observable<any> {
        return this.http.get(`${URL_API}categories-api`,{responseType:'json'});
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getAllCategories(): Observable<any> {
        return this.http.get(`${URL_API}categories-api-all`,{responseType:'json'});
    }
    //getCategories(parent: Partial<Category> = null, depth: number = 0): Observable<Category[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?parent=latest-news&depth=1
         *
         * where:
         * - parent = parent.slug
         * - depth  = depth
         */
        // const params: {[param: string]: string} = {
        //     parent: parent.slug,
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getShopCategoriesTree(parent ? parent.slug : null, depth);
    //}

    getBrands(): Observable<any> {
        return this.http.get(`${URL_API}brands-api`,{responseType:'json'});
    }

    /**
     * Returns an array of the specified categories.
     *
     * @param slugs - Array of slugs.
     * @param depth - Maximum depth of category tree.
     */
    getCategoriesBySlug(slugs: string[], depth: number = 0): Observable<Category[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?slugs=power-tools,measurement&depth=1
         *
         * where:
         * - slugs = slugs.join(',')
         * - depth = depth
         */
        // const params: {[param: string]: string} = {
        //     slugs: slugs.join(','),
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getShopCategoriesBySlugs(slugs, depth);
    }

    /**
     * Returns paginated products list.
     * If categorySlug is null then a list of all products should be returned.
     *
     * @param categorySlug         - Unique human-readable category identifier.
     * @param options              - Options.
     * @param options.page         - Page number (optional).
     * @param options.limit        - Maximum number of items returned at one time (optional).
     * @param options.sort         - The algorithm by which the list should be sorted (optional).
     * @param options.filterValues - An object whose keys are filter slugs and values ​​are filter values (optional).
     */
    getProductsLista(categorySlug?: string|null): Promise<any> {
        const apiurl = categorySlug ? `products-by-category/${categorySlug}` : 'products-api'
        return new Promise((resolve, reject) => {
            this.http.get(`${URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(err => console.log(err)
                )
        })
    }

    getProductsServiceLista(categorySlug?: string|null): Promise<any> {
        const apiurl = categorySlug ? `products-by-category/${categorySlug}` : 'products-conversiones'
        return new Promise((resolve, reject) => {
            this.http.get(`${URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(err => console.log(err)
                )
        })
    }

    getProductsCapacitaciones(): Promise<any>{

        const apiurl = 'trainings';
        //         {
        //             id: 235,
        //             title: "CAPACITACION VIRTUAL CONVERSIONES VEHICULOS ELECTRICOS, 1, 2, 3, 8 Y 9 DE MARZO 2022 EN UN HORARIO DE 8:30PM A 10:30PM",
        //             category: "Movilidad Híbrida y Eléctrica (Vehículos, Motos, Bicicletas y Scooters)",
        //             categoryId: 1051,
        //             categorySlug: "movilidad-electrica",
        //             price: 2500,
        //             priceOriginal: "1724.14",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "IMAGEN CAPACITACION ELECTRICOS.png"
        //             ],
        //             coverImage: "IMAGEN CAPACITACION ELECTRICOS.png",
        //             description: "<p>- FUNCIONAMIENTO DE UN AUTO ELECTRICO<\/p>\r\n<p>- ALMACENAMIENTO DE ENERGIA Y BATERIAS<\/p>\r\n<p>- COMPONENTES Y KITS PARA CONVERSIONES ELECTRICOS<\/p>\r\n<p>- PROCESO DE CONVERSION PASO A PASO<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 5,
        //             clases: 2,
        //             hrs: "10",
        //             extracto: "APRENDERAN TODO SOBRE VEHICULOS ELECTRICOS",
        //             date: "2022-03-01",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-virtual-conversiones-vehiculos-electricos-1-2-3-8-y-9-de-marzo-2022-en-un-horario-de-830pm-a-1030pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },
        //         {
        //             id: 236,
        //             title: "CAPACITACION PRESENCIAL CONVERSION DE AUTOS ELECTRICOS SABADO 26 DE 2:30PM A 7:30PM Y DOMINGO 27 DE FEBRERO 2022 DE 12:30PM A 5:30PM",
        //             category: "Movilidad Híbrida y Eléctrica (Vehículos, Motos, Bicicletas y Scooters)",
        //             categoryId: 1051,
        //             categorySlug: "movilidad-electrica",
        //             price: 4000,
        //             priceOriginal: "2758.62",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "WhatsApp Image 2022-01-18 at 2.53.43 PM.jpeg"
        //             ],
        //             coverImage: "IMAGEN CAPACITACION ELECTRICOS.png",
        //             description: "<p>APRENDAR&Aacute;S TODO SOBRE:<\/p>\r\n<p>- FUNCIONAMIENTO DEUN AUTO ELECTRICO.<\/p>\r\n<p>- INSTALACION Y PROCESO DE CONVERSION PASO A PASO.&nbsp;<\/p>\r\n<p>- ALMACENAMIENTO DE ENERGIA Y BATERIAS<\/p>\r\n<p>- COMPONENTES Y KITS DE UNA CONVERSION ELECTRICA.&nbsp;<\/p>\r\n<p>EL CURSO PRESENCIAL SE IMPARTIRA EN: LAGO CHAPALA 61, COL. ANAHUAC, MIGUEL HIDALGO, CP 11320, CDMX.<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 2,
        //             clases: 5,
        //             hrs: "10",
        //             extracto: "APRENDERAN TODO LO NECESARIO CON CONVERSIONES A ELECTRICOS",
        //             date: "2022-02-26",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-presencial-conversion-de-autos-electricos-sabado-26-de-230pm-a-730pm-y-domingo-27-de-febrero-2022-de-1230pm-a-530pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },
        //         {
        //             id: 238,
        //             title: "CAPACITACION VIRTUAL  EN INSTALACIONES A GAS NATURAL VEHICULAR (GNV) LOS DIAS 10,11 Y 12 DE MARZO 2022 DE 5PM A 6:30PM",
        //             category: "CONVERSIONES HIBRIDAS DUALES GASOLINA-GAS NATURAL VEHICULAR (GNV)",
        //             categoryId: 1058,
        //             categorySlug: "conversiones-duales-gasolina-gas-natural-comprimido-gnc",
        //             price: 2500,
        //             priceOriginal: "1724.14",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "CAPACITACION INSTALACIONES A GNV OK.jpg"
        //             ],
        //             coverImage: "CAPACITACION INSTALACIONES A GNV OK.jpg",
        //             description: "<p>- &iquest;QUE ES EL GAS NATURAL VEHICULAR?<\/p>\r\n<p>- MARCAS DE KITS EN EL MERCADO.<\/p>\r\n<p>- SEGURIDAD Y MARCO REGULATORIO EN INSTALACIONES GNV.<\/p>\r\n<p>- PROCESO DE INSTLACION DE UNA CONVERSION.&nbsp;<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 3,
        //             clases: 2,
        //             hrs: "6",
        //             extracto: "APRENDERAS TODO LO RELACIONADO A COMO REALIZAR UNA CONVERSION A GAS NATURAL VEHICULAR DE FORMA SEGURA",
        //             date: "2022-03-10",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-virtual-en-instalaciones-a-gas-natural-vehicular-gnv-los-dias-1011-y-12-de-marzo-2022-de-5pm-a-630pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },{
        //             id: 235,
        //             title: "CAPACITACION VIRTUAL CONVERSIONES VEHICULOS ELECTRICOS, 1, 2, 3, 8 Y 9 DE MARZO 2022 EN UN HORARIO DE 8:30PM A 10:30PM",
        //             category: "Movilidad Híbrida y Eléctrica (Vehículos, Motos, Bicicletas y Scooters)",
        //             categoryId: 1051,
        //             categorySlug: "movilidad-electrica",
        //             price: 2500,
        //             priceOriginal: "1724.14",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "IMAGEN CAPACITACION ELECTRICOS.png"
        //             ],
        //             coverImage: "IMAGEN CAPACITACION ELECTRICOS.png",
        //             description: "<p>- FUNCIONAMIENTO DE UN AUTO ELECTRICO<\/p>\r\n<p>- ALMACENAMIENTO DE ENERGIA Y BATERIAS<\/p>\r\n<p>- COMPONENTES Y KITS PARA CONVERSIONES ELECTRICOS<\/p>\r\n<p>- PROCESO DE CONVERSION PASO A PASO<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 5,
        //             clases: 2,
        //             hrs: "10",
        //             extracto: "APRENDERAN TODO SOBRE VEHICULOS ELECTRICOS",
        //             date: "2022-03-01",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-virtual-conversiones-vehiculos-electricos-1-2-3-8-y-9-de-marzo-2022-en-un-horario-de-830pm-a-1030pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },
        //         {
        //             id: 236,
        //             title: "CAPACITACION PRESENCIAL CONVERSION DE AUTOS ELECTRICOS SABADO 26 DE 2:30PM A 7:30PM Y DOMINGO 27 DE FEBRERO 2022 DE 12:30PM A 5:30PM",
        //             category: "Movilidad Híbrida y Eléctrica (Vehículos, Motos, Bicicletas y Scooters)",
        //             categoryId: 1051,
        //             categorySlug: "movilidad-electrica",
        //             price: 4000,
        //             priceOriginal: "2758.62",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "WhatsApp Image 2022-01-18 at 2.53.43 PM.jpeg"
        //             ],
        //             coverImage: "IMAGEN CAPACITACION ELECTRICOS.png",
        //             description: "<p>APRENDAR&Aacute;S TODO SOBRE:<\/p>\r\n<p>- FUNCIONAMIENTO DEUN AUTO ELECTRICO.<\/p>\r\n<p>- INSTALACION Y PROCESO DE CONVERSION PASO A PASO.&nbsp;<\/p>\r\n<p>- ALMACENAMIENTO DE ENERGIA Y BATERIAS<\/p>\r\n<p>- COMPONENTES Y KITS DE UNA CONVERSION ELECTRICA.&nbsp;<\/p>\r\n<p>EL CURSO PRESENCIAL SE IMPARTIRA EN: LAGO CHAPALA 61, COL. ANAHUAC, MIGUEL HIDALGO, CP 11320, CDMX.<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 2,
        //             clases: 5,
        //             hrs: "10",
        //             extracto: "APRENDERAN TODO LO NECESARIO CON CONVERSIONES A ELECTRICOS",
        //             date: "2022-02-26",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-presencial-conversion-de-autos-electricos-sabado-26-de-230pm-a-730pm-y-domingo-27-de-febrero-2022-de-1230pm-a-530pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },
        //         {
        //             id: 238,
        //             title: "CAPACITACION VIRTUAL  EN INSTALACIONES A GAS NATURAL VEHICULAR (GNV) LOS DIAS 10,11 Y 12 DE MARZO 2022 DE 5PM A 6:30PM",
        //             category: "CONVERSIONES HIBRIDAS DUALES GASOLINA-GAS NATURAL VEHICULAR (GNV)",
        //             categoryId: 1058,
        //             categorySlug: "conversiones-duales-gasolina-gas-natural-comprimido-gnc",
        //             price: 2500,
        //             priceOriginal: "1724.14",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "CAPACITACION INSTALACIONES A GNV OK.jpg"
        //             ],
        //             coverImage: "CAPACITACION INSTALACIONES A GNV OK.jpg",
        //             description: "<p>- &iquest;QUE ES EL GAS NATURAL VEHICULAR?<\/p>\r\n<p>- MARCAS DE KITS EN EL MERCADO.<\/p>\r\n<p>- SEGURIDAD Y MARCO REGULATORIO EN INSTALACIONES GNV.<\/p>\r\n<p>- PROCESO DE INSTLACION DE UNA CONVERSION.&nbsp;<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 3,
        //             clases: 2,
        //             hrs: "6",
        //             extracto: "APRENDERAS TODO LO RELACIONADO A COMO REALIZAR UNA CONVERSION A GAS NATURAL VEHICULAR DE FORMA SEGURA",
        //             date: "2022-03-10",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-virtual-en-instalaciones-a-gas-natural-vehicular-gnv-los-dias-1011-y-12-de-marzo-2022-de-5pm-a-630pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },{
        //             id: 238,
        //             title: "CAPACITACION VIRTUAL  EN INSTALACIONES A GAS NATURAL VEHICULAR (GNV) LOS DIAS 10,11 Y 12 DE MARZO 2022 DE 5PM A 6:30PM",
        //             category: "CONVERSIONES HIBRIDAS DUALES GASOLINA-GAS NATURAL VEHICULAR (GNV)",
        //             categoryId: 1058,
        //             categorySlug: "conversiones-duales-gasolina-gas-natural-comprimido-gnc",
        //             price: 2500,
        //             priceOriginal: "1724.14",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "CAPACITACION INSTALACIONES A GNV OK.jpg"
        //             ],
        //             coverImage: "CAPACITACION INSTALACIONES A GNV OK.jpg",
        //             description: "<p>- &iquest;QUE ES EL GAS NATURAL VEHICULAR?<\/p>\r\n<p>- MARCAS DE KITS EN EL MERCADO.<\/p>\r\n<p>- SEGURIDAD Y MARCO REGULATORIO EN INSTALACIONES GNV.<\/p>\r\n<p>- PROCESO DE INSTLACION DE UNA CONVERSION.&nbsp;<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 3,
        //             clases: 2,
        //             hrs: "6",
        //             extracto: "APRENDERAS TODO LO RELACIONADO A COMO REALIZAR UNA CONVERSION A GAS NATURAL VEHICULAR DE FORMA SEGURA",
        //             date: "2022-03-10",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-virtual-en-instalaciones-a-gas-natural-vehicular-gnv-los-dias-1011-y-12-de-marzo-2022-de-5pm-a-630pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         },{
        //             id: 238,
        //             title: "CAPACITACION VIRTUAL  EN INSTALACIONES A GAS NATURAL VEHICULAR (GNV) LOS DIAS 10,11 Y 12 DE MARZO 2022 DE 5PM A 6:30PM",
        //             category: "CONVERSIONES HIBRIDAS DUALES GASOLINA-GAS NATURAL VEHICULAR (GNV)",
        //             categoryId: 1058,
        //             categorySlug: "conversiones-duales-gasolina-gas-natural-comprimido-gnc",
        //             price: 2500,
        //             priceOriginal: "1724.14",
        //             price_sale: null,
        //             discount: null,
        //             pictures: [
        //                 "CAPACITACION INSTALACIONES A GNV OK.jpg"
        //             ],
        //             coverImage: "CAPACITACION INSTALACIONES A GNV OK.jpg",
        //             description: "<p>- &iquest;QUE ES EL GAS NATURAL VEHICULAR?<\/p>\r\n<p>- MARCAS DE KITS EN EL MERCADO.<\/p>\r\n<p>- SEGURIDAD Y MARCO REGULATORIO EN INSTALACIONES GNV.<\/p>\r\n<p>- PROCESO DE INSTLACION DE UNA CONVERSION.&nbsp;<\/p>",
        //             stock: 1000,
        //             currency: "",
        //             video: null,
        //             sessions: 3,
        //             clases: 2,
        //             hrs: "6",
        //             extracto: "APRENDERAS TODO LO RELACIONADO A COMO REALIZAR UNA CONVERSION A GAS NATURAL VEHICULAR DE FORMA SEGURA",
        //             date: "2022-03-10",
        //             new: null,
        //             sale: null,
        //             variants: [],
        //             slug: "capacitacion-virtual-en-instalaciones-a-gas-natural-vehicular-gnv-los-dias-1011-y-12-de-marzo-2022-de-5pm-a-630pm",
        //             status: 1,
        //             activated: 1,
        //             user: null
        //         }
        //     ],
        //     links: {
        //         first: "https:\/\/eneralt.com.mx\/apieneralt\/public\/api\/trainings?page=1",
        //         last: "https:\/\/eneralt.com.mx\/apieneralt\/public\/api\/trainings?page=1",
        //         prev: null,
        //         next: null
        //     },
        //     meta: {
        //         current_page: 1,
        //         from: 1,
        //         last_page: 1,
        //         path: "https:\/\/eneralt.com.mx\/apieneralt\/public\/api\/trainings",
        //         per_page: 9,
        //         to: 3,
        //         total: 3
        //     }
        // };
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })

    }

    getSearchCapacitaciones(term: string): Promise<any>{
        const apiurl= `trainings/search/${term}`;
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    getProductsServices(): Promise<any>{

        const apiurl = 'products-conversiones';
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })

    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
     }

     getProductsListService(categorySlug: string|null, options: ListOptions): Observable<ProductsList> {
        return getProductsList(categorySlug, options);
     }

    getProductsList(categorySlug: string|null, options: ListOptions): Observable<ProductsList> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?category=screwdriwers&page=2&limit=12&sort=name_desc&filter_price=500-1000
         *
         * where:
         * - category     = categorySlug
         * - page         = options.page
         * - limit        = options.limit
         * - sort         = options.sort
         * - filter_price = options.filterValues.price
         */

        // const URL = 'http://localhost/apieneralt/public/api/products-api';

        // const params: {[param: string]: string} = {};

        // if (categorySlug) {
        //     params.category = categorySlug;
        // }
        // if ('page' in options) {
        //     params.page = options.page.toString();
        // }
        // if ('limit' in options) {
        //     params.limit = options.limit.toString();
        // }
        // if ('sort' in options) {
        //     params.sort = options.sort;
        // }
        // if ('filterValues' in options) {
        //     Object.keys(options.filterValues).forEach(slug => params[`filter_${slug}`] = options.filterValues[slug]);
        // }

        // getProductsList(categorySlug, options).subscribe( (resp) => {
        //     console.log(resp);
        // });

        // this.http.get<ProductsList>(URL, {params}).subscribe( (resp) => {
        //     console.log(resp);
        // });


        // return this.http.get<ProductsList>(URL, {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProductsList(categorySlug, options);
    }

    getProduct(productSlug: string): Observable<Product> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/electric-planer-brandix-kl370090g-300-watts.json
         *
         * where:
         * - electric-planer-brandix-kl370090g-300-watts = productSlug
         */
        // return this.http.get<Product>(`https://example.com/api/products/${productSlug}.json`);

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProduct(productSlug);
    }

    /**
     * Returns popular brands.
     */
    getPopularBrands(): Observable<Brand[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/brands/popular.json
         */
        // return this.http.get<Brand[]>('https://example.com/api/shop/brands/popular.json');

        // This is for demonstration purposes only. Remove it and use the code above.
        return getBrands();
    }

    getBestsellers(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/bestsellers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/bestsellers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getBestsellers(limit);
    }

    getTopRated(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/top-rated.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/top-rated.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getTopRated(limit);
    }

    getSpecialOffers(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/special-offers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/special-offers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getSpecialOffers(limit);
    }

    getFeaturedProducts(categorySlug: string = null, limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/featured.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (category) {
        //     params.category = category;
        // }
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/featured.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getFeatured(categorySlug, limit);
    }

    getLatestProducts(categorySlug: string = null, limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/latest.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (category) {
        //     params.category = category;
        // }
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/latest.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getLatestProducts(categorySlug, limit);
    }

    getRelatedProducts(product: Partial<Product>): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/related.json?for=water-tap
         *
         * where:
         * - for = product.slug
         */
        // const params: {[param: string]: string} = {
        //     for: product.slug,
        // };
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/related.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getRelatedProducts(product);
    }

    getSuggestions(query: string, limit: number, categorySlug: string = null) {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query = query
         * - limit = limit
         * - category = categorySlug
         */
        // const params: {[param: string]: string} = {query, limit: limit.toString()};
        //
        // if (categorySlug) {
        //     params.category = categorySlug;
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/search/suggestions.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.

        const data = {
            query,
            limit,
            categorySlug
        }
        const apiurl = 'get-products-search/' + data.query + '/' + data.limit +'/'+ data.categorySlug;
        return this.http.get(`${this.URL_API}${apiurl}`);
        // return getSuggestions(query, limit, categorySlug);
    }

    getSearchTerm(term: string, category) {
        // const user = this._companyService.user.id;
        let url = URL_API + 'get-products-search/' + term + '/' + 20000 + '/' + category +`?page=${this.sharedService.pageActualSearch}`;
        return new Promise((resolve, reject) => {
            this.http.get(url)
            .toPromise()
            .then(resp => {
                resolve(resp)
            })
            .catch(err => console.error(err)
            )
        })
    }

    getSearchTermUrl(url: string, sort?) {
        return new Promise((resolve, reject) => {
                this.http.get(url)
                .toPromise()
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => console.error(err)
                )
            })
    }
}
