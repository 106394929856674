<!-- .block-features -->
<div class="block block-features block-features--layout--{{ layout }}">
    <div class="container">
        <div class="block-features__list">
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-free-delivery-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">ENTREGA</div>
                    <div class="block-features__subtitle">En todo México</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-24-hours-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">SOPORTE 24/7</div>
                    <div class="block-features__subtitle">Un asesor te atendera</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-payment-security-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">100% SEGURO</div>
                    <div class="block-features__subtitle">Compra asegurada</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-tag-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">PROMOCIONES</div>
                    <div class="block-features__subtitle">Pregunta por nuestras promociones</div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- .block-features / end -->