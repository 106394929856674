import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICES, URL_SERVICES_IMAGES } from 'src/app/config/config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DirectoryService {

    URL_API = URL_SERVICES;
    URL_SERVICES_IMG = URL_SERVICES_IMAGES;
    URL_API_PROD = this.URL_API + 'directorio'
    URL_API_GET_DIRECTORY = this.URL_API + 'obtener-directorio'
    
    constructor(private http: HttpClient) {}

    getDirectories():Observable<any>{
        return this.http.get(this.URL_API_PROD);
    }
    getDirectory(slug:string):Observable<any>{
        return this.http.get(`${this.URL_API_GET_DIRECTORY}/${slug}`);
    }


}
