<div class="site-footer__widget footer-contacts">
    <img src="assets/images/logos/logo_footer.png" alt="" width="160px">

    <!-- <div class="footer-contacts__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in feugiat lorem. Pellentque ac placerat tellus.
    </div> -->

    <ul class="footer-contacts__contacts">
        <li><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ store.address }}</li>
        <li><i class="footer-contacts__icon far fa-envelope"></i> {{ store.email }}</li>
        <li><i class="footer-contacts__icon fas fa-mobile-alt"></i> {{ store.phone }}</li>
        <!-- <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li> -->
    </ul>
</div>
