import { Component } from '@angular/core';
import { CurrencyService } from '../../../../shared/services/currency.service';

@Component({
    selector: 'app-header-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
    languages = [
        {name: 'English', image: 'language-1'},
        {name: 'French',  image: 'language-2'},
        {name: 'German',  image: 'language-3'},
        {name: 'Russian', image: 'language-4'},
        {name: 'Italian', image: 'language-5'}
    ];

    currencies = [
        {name: '€ Euro',           url: '', code: 'EUR', symbol: '€'},
        {name: '£ Pound Sterling', url: '', code: 'GBP', symbol: '£'},
        {name: '$ US Dollar',      url: '', code: 'USD', symbol: '$'},
        {name: '₽ Russian Ruble',  url: '', code: 'RUB', symbol: '₽'}
    ];

    dolar: number;
    euro: number;

    constructor(
        public currencyService: CurrencyService
    ) { 
        fetch("https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=9c7cd125bd363263fd7f76f9ea028abb69ae5fcd0965ef400d44591eb5e2a4db")
        .then(respuesta => respuesta.json())
        .then(res => {
            this.dolar = res['bmx']['series'][0]['datos'][0]['dato'];
        });

        fetch("https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF46410/datos/oportuno?token=9c7cd125bd363263fd7f76f9ea028abb69ae5fcd0965ef400d44591eb5e2a4db")
        .then(respuesta => respuesta.json())
        .then(res => {
            this.euro = res['bmx']['series'][0]['datos'][0]['dato'];
        });
    }

    setCurrency(currency): void {
        this.currencyService.options = {
            code: currency.code,
            display: currency.symbol,
        };
    }
}
